import { MouseEvent, useState } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from '@hooks/useAppSelector';
import * as favoritePropertiesActions from '@redux/modules/favoriteProperties';
import IconHeartLine from '@svg-icons/IconHeartLine';
import { useActions } from '@utils/hooks';
import { checkIsPropertyFavored } from '@utils/neighbor';
import toast from '@utils/toast';
import { isEmpty } from '@utils/utils';
import styles from './css';

const FavoriteButton = ({ propertyId }: { propertyId: string }) => {
  const {
    authUser,
    favoriteProperties: { favorite_properties },
  } = useAppSelector((state) => ({
    authUser: state.authUser,
    favoriteProperties: state.favoriteProperties,
  }));
  const { addFavoriteProperty, deleteFavoriteProperty } = useActions(
    favoritePropertiesActions
  );

  const { locale } = useRouter();
  const isFavored =
    favorite_properties &&
    checkIsPropertyFavored(favorite_properties, Number(propertyId));
  const [isLoading, setIsLoading] = useState(false);

  const onClickFavoriteButton = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    // To avoid transition to PDP which has under element
    event.preventDefault();
    event.stopPropagation();

    if (isLoading) {
      return;
    }

    if (isEmpty(authUser)) {
      return toast.error('error.requireLogin', true);
    }
    setIsLoading(true);

    if (isFavored) {
      await deleteFavoriteProperty(authUser, locale, propertyId);
    } else {
      await addFavoriteProperty(authUser, locale, propertyId);
    }
    setIsLoading(false);
  };

  return (
    <button
      aria-label="favorite"
      className="favorite-button"
      type="button"
      onClick={onClickFavoriteButton}
    >
      <IconHeartLine isFavored={isFavored} />
      <style jsx={true}>{styles}</style>
    </button>
  );
};

export default FavoriteButton;
