import Image from 'next/image';
import { useRouter } from 'next/router';
import FavoriteButton from '@atoms/FavoriteButton';
import Link from '@components/Link';
import { useNeighbor } from '@hooks/useNeighbor';
import { Property } from '@services/hafh/types/generated';
import routes, { route } from '@utils/routes';
import { pushDataLayer } from '@utils/spa-ga4';
import CoinInfoWithGuestsAndNights from '../CoinInfoWithGuestsAndNights';
import styles from './css';

type Props = {
  property: Property;
  sizeString?: string;
  sliderType?: 'campaignProperties' | 'lp' | 'planSelect' | 'ranking' | null;
};

const PropertySliderItem = ({
  property,
  sizeString = '150px, (min-width: 680px) 280px',
  sliderType = null,
}: Props) => {
  const { neighbor } = useNeighbor();
  const { pathname } = useRouter();

  const onClickProperty = () => {
    if (pathname === routes.inviteDetail) {
      pushDataLayer({
        campaign_code: '',
        event: 'gtm-click',
        event_name: `button_property-${property.id}`,
        neighbor_id: neighbor?.id,
      });
    }

    if (sliderType === 'campaignProperties') {
      pushDataLayer({
        campaign_code: '20240809_all_taiwan-hotel',
        event: 'gtm-click',
        event_name: `card_taiwan_${property.id}`,
        neighbor_id: neighbor?.id,
      });
    }
  };

  return (
    <div className="swiper-slide property-slider-item">
      <Link
        href={route(routes.property, { id: property.id })}
        target="_blank"
        onClick={onClickProperty}
      >
        <div className="property-image-container">
          <div className="property-image">
            <Image
              alt={property.name}
              fill={true}
              sizes={sizeString}
              src={property.thumbnail_url}
              style={{
                objectFit: 'cover',
              }}
            />
          </div>
          {sliderType !== 'planSelect' && sliderType !== 'lp' && (
            <div className="favorite-button-container">
              <FavoriteButton propertyId={String(property.id)} />
            </div>
          )}
        </div>
        <div className="property-box">
          <div className="property-box-inner">
            <h3 className="property-title">{property.name}</h3>
            {sliderType !== 'planSelect' && (
              <p className="property-content">{property.prefecture}</p>
            )}
            <CoinInfoWithGuestsAndNights minCoin={property.min_coin} />
          </div>
        </div>
      </Link>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default PropertySliderItem;
