import IconHeartOutline from '@assets/icon-heart-outline.svg';
import IconHeartSvg from '@assets/icon-heart.svg';

type Props = {
  isFavored: boolean;
};

const IconHeartLine = ({ isFavored = false }: Props) => (
  <div>{isFavored ? <IconHeartSvg /> : <IconHeartOutline />}</div>
);

export default IconHeartLine;
