import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .property-slider-item {
    cursor: pointer;
    background: ${COLORS.white};
    border-radius: 8px;
    box-shadow: 0 4px 4px -2px rgba(24, 39, 75, 0.08);
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 8px 8px -2px rgba(24, 39, 75, 0.12);
    }
    .property-image-container {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      overflow: hidden;
      position: relative;
      .property-image {
        padding-top: 66%;
        width: 100%;
        position: relative;
      }
      .favorite-button-container {
        position: absolute;
        top: 8px;
        right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
      }
    }
    .property-box {
      margin: 12px 8px 12px 12px;
      padding-bottom: 12px;
      display: flex;
      justify-content: space-between;
      .property-box-inner {
        width: 100%;
      }
      .property-title {
        font-size: 14px;
        color: ${COLORS.black900};
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
      .property-content {
        font-size: 12px;
        color: ${COLORS.black600};
      }

      .property-coin {
        min-height: 75px;
      }
    }
    .property-no-rooms {
      margin-top: 4px;
    }
  }
`;

export default styles;
